import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { XApiKeyService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { INotificationRes } from './types';

export function useGetMmNotification(
  options?: UseQueryOptions<ApiResponseType<INotificationRes>, Error, INotificationRes>
) {
  const clearLocalApiKey = () => {
    return setTimeout(() => {
      XApiKeyService.clearApiKey();
    }, 100);
  };

  const {
    data: notification,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetNotification,
  } = useQuery<ApiResponseType<INotificationRes>, Error, INotificationRes>(
    [API_QUERIES.MM_NOTIFICATION],
    {
      queryFn: (query) => {
        XApiKeyService.setApiKey();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<INotificationRes>>(
          apiClient.getMnNotification,
          params
        );
      },
      onSuccess() {
        clearLocalApiKey();
        return;
      },
      onError() {
        clearLocalApiKey();
        return;
      },
      select: getResponseData,
      ...options,
    }
  );

  const queryClient = useQueryClient();
  const handleInvalidatePOPaymentDetail = (id: string) =>
    queryClient.invalidateQueries([API_QUERIES.MM_NOTIFICATION, { id }]);

  return {
    notification,
    error,
    isError,
    isLoading,
    onGetNotification,
    handleInvalidatePOPaymentDetail,
  };
}
